import { NgModule } from '@angular/core';
import { ActiveNavItemPipe, NavItemsPipe } from '../../pipes';
import { HeaderNavItemsComponent } from './header-nav-items.component';
import { SharedModule } from '@shared/shared.module';
import { NavItemRoutePipe } from '../../pipes/nav-item-route.pipe';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
	declarations: [HeaderNavItemsComponent, NavItemsPipe, ActiveNavItemPipe, NavItemRoutePipe],
	imports: [SharedModule, MatMenuModule],
	exports: [HeaderNavItemsComponent],
})
export class HeaderNavItemsModule {}
