import { Pipe, PipeTransform } from '@angular/core';
import { AisleType, SectionType } from '@core/enums';
import { Aisle, Section, Stand } from '@core/models';

@Pipe({
	name: 'navItems',
})
export class NavItemsPipe implements PipeTransform {
	transform(sectionList: Section[], type: string): Stand[] | Aisle[] {
		let navItemList = [];

		switch (type) {
			case AisleType.SHARE_RESIDENTIAL:
				navItemList =
					sectionList
						.find((section: Section) => section.reference === SectionType.PROPERTIES_FOR_RENT)
						.aisles.find((aisle: Aisle) => aisle.reference === AisleType.SHARE_RESIDENTIAL)?.stands || [];

				break;
			case AisleType.RENT_RESIDENTIAL:
				navItemList = sectionList
					.find((section: Section) => section.reference === SectionType.PROPERTIES_FOR_RENT)
					.aisles.filter((aisle: Aisle) => aisle.reference === AisleType.RENT_RESIDENTIAL || aisle.reference === AisleType.RENT_COMMERCIAL);

				break;
			case SectionType.PROPERTIES_FOR_SALE:
				navItemList = sectionList.find((section: Section) => section.reference === SectionType.PROPERTIES_FOR_SALE).aisles || [];

				break;
			default:
		}

		return navItemList;
	}
}
