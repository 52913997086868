import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ProfileDisabledDialogComponent } from './profile-disabled-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
	declarations: [ProfileDisabledDialogComponent],
	imports: [SharedModule, MatDialogModule],
})
export class ProfileDisabledDialogModule {}
