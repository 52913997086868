import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'navItemRoute',
})
export class NavItemRoutePipe implements PipeTransform {
	transform(routes: string[], reference: string): string[] {
		return [...routes, reference].filter((route: string) => !!route);
	}
}
