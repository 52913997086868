import { Pipe, PipeTransform } from '@angular/core';
import { AisleType, SectionType } from '@core/enums';

@Pipe({
	name: 'activeNavItem',
})
export class ActiveNavItemPipe implements PipeTransform {
	transform(navItem: string, route: string): boolean {
		let isMatch = false;

		switch (navItem) {
			case AisleType.SHARE_RESIDENTIAL:
				isMatch = route === AisleType.SHARE_RESIDENTIAL;

				break;
			case AisleType.RENT_RESIDENTIAL:
				isMatch = route === AisleType.RENT_RESIDENTIAL || route === AisleType.RENT_COMMERCIAL;

				break;
			case SectionType.PROPERTIES_FOR_SALE:
				isMatch = route === SectionType.PROPERTIES_FOR_SALE;

				break;
			default:
		}

		return isMatch;
	}
}
